import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useImages, useLoader } from '../../../hooks';
import { shopLang } from '../../../../config/config';
import { checkAgent } from '../helpers';
import ModalBE from '../parts/ModalBE';
import Intro from './parts/Intro';
import Chest from './parts/Chest';
import Keys from '../parts/Keys';
import {Container2, Wrapper} from './Phase2.styles';
import * as images from '../assets';
import * as sprites from './assets/sprites';
import * as imagesPhase2 from './assets/images';

const Phase2 = () => {
  const checkDevice = useMemo(() => checkAgent(), []);
  const assets = useMemo(() =>
      checkDevice
        ? {...sprites, ...images, ...imagesPhase2}
        : {...images, ...imagesPhase2},
    [checkDevice]);
  const isAssetsLoaded = useImages(assets);

  const isShowModal = useSelector((state: any) => state?.isShowModalEYC);
  const keysGold = useSelector((state: any) => state?.transactionsEYC?.gold);
  const currentPrize = useSelector((state: any) => state?.currentPrizeDataEYC);
  const isUserWonEYC = useSelector((state: any) => state?.isUserWonEYC);
  const isDefineWinnerCompleted = useSelector((state: any) => state?.isDefineWinnerCompletedEYC);

  const loader = useLoader(!isAssetsLoaded || typeof keysGold !== 'number');

  return (
    loader ||
    <Wrapper>
      <Container2>
        <Intro />

        <Chest />

        <Keys phase={2}
              keysGold={keysGold}
              isVisible={!(isUserWonEYC && isDefineWinnerCompleted && currentPrize?.prize?.code === '1000000')} />

        {shopLang !== 'nl_nl' && isShowModal && <ModalBE />}
      </Container2>
    </Wrapper>
  );
};

export default Phase2;