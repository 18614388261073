import { prepr } from '../services/prepr';

import { getLocalePrepr } from '../../components/utils/_app-helpers';

let locale = getLocalePrepr();
const getProfileMenuLinks = prepr
  .graphqlQuery(
    `{
      ProfileMenuLinks(limit: null, locale: "${locale}") {
        items {
            cta_label
            cta_icon
            cta_active_icon
            cta_url
            order
            is_cta_url_external
            internal_link { 
              __typename
              ... on Page {
                  _id
                  _slug
                }
              }
          }
        }
      }`
  )
  .fetch()
  .then((res: any) => {
    return res.data.ProfileMenuLinks.items})
  .catch((error: string) => error);

export default getProfileMenuLinks;
