import styled, { css } from 'styled-components';
import { ProfileBannerBlob } from '../../../assets';

export const ProfileBannerWrapper = styled.div(({ theme }) => {
  const { mediaQueries } = theme;

  return css`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 25px;
    width: 100%;
    min-height: 250px;
    max-width: 260px;
    margin-bottom: 20px;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      width: 100%;
      height: 250px;
      background: url('${ProfileBannerBlob}') no-repeat bottom center;
      background-size: cover;

      ${mediaQueries.md} {
        height: 190px;
      }

      ${mediaQueries.xs} {
        height: 180px;
      }
    }

    ${mediaQueries.md} {
      text-align: center;
      margin: 0 auto 20px;
    }
  `;
});

export const Intro = styled.p(({ theme }) => {
  const { font, size, color, mediaQueries } = theme;

  return css`
    position: relative;
    font-size: ${font.family.EMPrintRegular};
    padding-bottom: 25px;

    span {
      color: ${color.white};
      line-height: 1.2;

      &:first-child {
        font-weight: ${font.weight.regular};
        font-size: ${size.px18};

        ${mediaQueries.md} {
          font-weight: ${font.weight.semiBold};
          font-size: ${size.px16};
        }
      }

      &:last-child {
        font-weight: ${font.weight.semiBold};
        font-size: ${size.px32};

        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;


        ${mediaQueries.md} {
          font-size: ${size.px22};
        }
      }
    }

    ${mediaQueries.xs} {
      padding-bottom: 15px;
    }
  `;
});