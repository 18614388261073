import React, { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import i18n from '../../../../../../config/i18n';
import { checkLoggedIn } from '../../../../../../components/utils/_app-helpers';
import HeaderProfileMenu from '../../../../HeaderProfileMenu';
import { IconCheckedComponent, IconDeleteComponent } from '../../../../../assets';

import {
  ProfileMenuWrapper,
  ProfileWrapper,
  ProfileIcon,
  ProfileDetails,
  ProfileText, ProfileButton
} from './Profile.styles';

function Profile() {
  const location = useLocation();
  const isLoggedIn = checkLoggedIn();
  const firstName = useSelector((state: any) => state?.currentUser?.firstName);
  const [openMenu, setOpenMenu] = useState(false);
  const profileBtnRef = useRef<HTMLButtonElement>(null);
  const profileMenuRef = useRef<HTMLDivElement>(null);

  const toggleMenu = () => {
    if (openMenu) {
      setOpenMenu(false);
    } else {
      setOpenMenu(true);
    }
  };

  useEffect(() => {
    const handleCloseMenu = (e: any) => {
      const isProfileBtn = profileBtnRef?.current?.contains(e?.target);
      const isInsideProfileWrapper = profileMenuRef?.current?.contains(e?.target);
      const isLinkClickInsideMenu = e?.target?.closest('a') && profileMenuRef?.current?.contains(e?.target.closest('a'));
      const QRCodeModal = document.getElementById('modalPortal');
      const isInsideQRCodeModal = QRCodeModal?.contains(e?.target);

      if ((openMenu && isInsideProfileWrapper && isLinkClickInsideMenu) || (openMenu && !isInsideProfileWrapper && !isProfileBtn && !isInsideQRCodeModal)) {
        setOpenMenu(false);
      }
    };

    document.addEventListener('click', handleCloseMenu, true);

    return () => {
      document.removeEventListener('click', handleCloseMenu, true);
    };
  }, [openMenu]);

  return (
    <>
      <ProfileWrapper>
        <ProfileButton ref={profileBtnRef} onClick={() => toggleMenu()}>
          <ProfileIcon isDeleteStatus={location?.pathname === '/delete-account'}>
            {location?.pathname !== '/delete-account' &&
              (isLoggedIn ?
                  <IconCheckedComponent /> :
                  <IconDeleteComponent />
              )
            }
          </ProfileIcon>
          <ProfileDetails openMenu={openMenu}>
            {
              isLoggedIn ?
                <ProfileText>{firstName}</ProfileText> :
                <ProfileText>{i18n.t('profile_name_unlogged')}</ProfileText>
            }
          </ProfileDetails>
        </ProfileButton>

        <ProfileMenuWrapper ref={profileMenuRef} className={openMenu ? 'active' : undefined}>
          <HeaderProfileMenu />
        </ProfileMenuWrapper>

      </ProfileWrapper>
    </>
  );
}

export default Profile;
