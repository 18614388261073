import styled, { css } from 'styled-components';

export const WrapperLogged = styled.div(({ theme }) => {
  const { color, mediaQueries } = theme;

  return css`
    user-select: none;
    border: 1px solid ${color.cottonGrey};
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    overflow: hidden;

    ${mediaQueries.md} {
      border: none;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      background-color: ${color.white};
    }
  `;
});

export const WrapperUnlogged = styled.div(({ theme }) => {
  const { font, size, color } = theme;

  return css`
    user-select: none;
    background: ${color.lightblue};
    padding: 15px;

    svg {
      max-width: 100%;
      height: auto;
      max-height: 24px;
      vertical-align: bottom;
    }

    p {
      margin-top: 10px;
      font-family: ${font.family.EMPrintRegular};
      font-size: ${size.px14};
      color: ${color.graniteGray};
      font-weight: ${font.weight.regular};
    }

    a {
      font-family: ${font.family.EMPrintRegular};
      font-size: ${size.px14};
      color: ${color.blue};
      font-weight: ${font.weight.regular};
    }
  `;
});