import React from 'react';
import { useSelector } from 'react-redux';
import i18n from '../../../../config/i18n';
import { EssoCard } from '../index';
import { ProfileBannerWrapper, Intro } from './ProfileBanner.styles';

const ProfileBanner = () => {
  const firstName = useSelector((state: any) => state?.currentUser?.firstName);

  return (
    <ProfileBannerWrapper>
      <Intro>
        <span>{i18n.t('profile_banner_intro_text')}</span>&nbsp;
        <br />
        <span>{firstName}</span>
      </Intro>
      <EssoCard isProfileMenu={true} />
    </ProfileBannerWrapper>
  );
};

export default ProfileBanner;