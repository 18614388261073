import React, { useCallback, useEffect, useRef, useState, useMemo } from 'react';
// import { store } from 'redux/store';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import * as Sentry from '@sentry/react';
import { CSSTransition } from 'react-transition-group';
import i18n from '../../../../../../config/i18n';
import { useWindowSize } from '../../../../../hooks';
import Toast from '../../../parts/Toast';
import ResultModal from '../ResultModal';
import clearLocaleStorage from '../../../../../utils/clearLocaleStorage';
import { checkAgent } from '../../../helpers';
import APIService from '../../../../../../prepr/components/EndYearCampaignCustom/apiEndYearCampaign/api';
import { shopLang } from '../../../../../../config/config';
import {
  ChestWrapper,
  ChestBox,
  ChestRow,
  ChestAnimation,
  ChestInner,
  PointsIOS1M,
  PointsIOS1000,
  PointsIOS500,
  PointsIOS250,
  LostIOS,
  TIME_IOS_ANIMATION
} from './Chest.styles';
import { Overlay } from '../../Phase2.styles';
import { ChestPosterNL, ChestPosterFR } from '../../assets/images';
import {
  Points_250_mp4,
  Points_250_webm,
  Points_500_mp4,
  Points_500_webm,
  Points_1000_mp4,
  Points_1000_webm
} from '../../../Phase1/assets/video';
import {
  Points_1M_mp4,
  Points_1M_webm,
  Chest_win_fr_mp4,
  Chest_win_fr_webm,
  Chest_win_nl_mp4,
  Chest_win_nl_webm,
  Lose_fr_mp4,
  Lose_fr_webm,
  Lose_nl_mp4,
  Lose_nl_webm
} from '../../assets/video';

function Chest() {
  const history = useHistory();
  const location = useLocation();
  const windowSize = useWindowSize();

  const checkDevice = useMemo(() => checkAgent(), []);
  const currentPrize = useSelector((state: any) => state?.currentPrizeDataEYC);

  const campaignId = useSelector((state: any) => state?.infoEYC?.id);
  const isReplayGame = useSelector((state: any) => state?.isReplayGameEYC);
  const isShowModal = useSelector((state: any) => state?.isShowModalEYC);
  const isModalAnswerCorrect = useSelector((state: any) => state?.isModalAnswerCorrectEYC);
  const isGameResultAnimated = useSelector((state: any) => state?.isGameResultAnimatedEYC);
  const isDefineWinnerCompleted = useSelector((state: any) => state?.isDefineWinnerCompletedEYC);
  const keysBlue = useSelector((state: any) => state?.transactionsEYC?.blue);
  const keysGold = useSelector((state: any) => state?.transactionsEYC?.gold);
  const winPoints = useSelector((state: any) => state?.winPointsEYC);
  const isUserWon = useSelector((state: any) => state?.isUserWonEYC);
  const chestAnimationRef = useRef<HTMLDivElement>(null);
  const chestRowRef = useRef<HTMLDivElement>(null);
  const chestBoxRef = useRef<HTMLDivElement>(null);
  const chestVideoRef = useRef<HTMLVideoElement>(null);
  const points_VideoRef = useRef<HTMLVideoElement>(null);
  const [activeVideo, setActiveVideo] = useState<number>(0);
  const [activeChest, setActiveChest] = useState<number>(0);
  const [isModalChestOpen, setIsModalChestOpen] = useState(false);
  const [isInfoChestShow, setIsInfoChestShow] = useState(false);
  const [pointsVideo, setPointsVideo] = useState<{ mp4: any; webm: any } | null>(null);
  const [IOSAnimation, setIOSAnimation] = useState<any>(null);
  const winPointsCounter = useRef(winPoints);
  const tokensCounter = useRef({
    blue: keysBlue,
    gold: keysGold
  });

  const defineWinner = useCallback(async () => {
    try {
      const prize = await APIService.createParticipantWithLottery({ campaignGameId: campaignId });
      return await Promise.resolve(prize);

      // store.dispatch(setCurrentPrize({prize: {code: '1000000'}}));
      // return await Promise.resolve({ lotteryPrize: {prize: {code: '1000000'}}});

      // store.dispatch(setCurrentPrize({prize: {code: '500'}}));
      // return await Promise.resolve({ lotteryPrize: {prize: {code: '500'}}});

      // store.dispatch(setCurrentPrize({prize: null}));
      // return await Promise.resolve({ lotteryPrize: {prize: null}});
    } catch (error) {
      setActiveChest(0);
      Sentry.captureMessage(`Error in createParticipantWithLottery: ${error}`, `info`);
      return await Promise.reject(error);
    }
  }, [campaignId]);

  const handleControlAfterGame = useCallback(async (points: number = 0) => {
    try {
      await APIService.lotterySync();
      APIService.setIsReplayGame(true);
      tokensCounter.current = {
        blue: tokensCounter.current.blue,
        gold: points === 1000000 ? 0 : tokensCounter.current.gold - 1
      };
      winPointsCounter.current = winPointsCounter.current + points;
    } catch (error) {
      console.info('Something is wrong - handleControlAfterGame');
      return await Promise.reject(error);
    }
  }, []);

  const onPlayGame = useCallback(() => {
    const dateNow = Date.now();
    const accessExpiresAt = localStorage.getItem('accessExpiresAt');
    const authorized = localStorage.getItem('authorized');

    if (authorized && accessExpiresAt && (dateNow >= +accessExpiresAt)) {
      clearLocaleStorage();
      history.push({
        pathname: '/auth/login',
        state: {
          prevPath: location.pathname
        }
      });
    } else {
      defineWinner()
        .then((response) => {
          APIService.setIsGameResultAnimated(true);

          if (response?.lotteryPrize?.prize) {
            APIService.setIsUserWon(true);
            if (response?.lotteryPrize?.prize?.code === '1000000') {
              checkDevice
                ? setIOSAnimation(<PointsIOS1M locale={shopLang} />)
                : setPointsVideo({
                  mp4: Points_1M_mp4,
                  webm: Points_1M_webm
                });
            }
            if (response?.lotteryPrize?.prize?.code === '1000') {
              checkDevice
                ? setIOSAnimation(<PointsIOS1000 locale={shopLang} />)
                : setPointsVideo({
                  mp4: Points_1000_mp4,
                  webm: Points_1000_webm
                });
            }
            if (response?.lotteryPrize?.prize?.code === '500') {
              checkDevice
                ? setIOSAnimation(<PointsIOS500 locale={shopLang} />)
                : setPointsVideo({
                  mp4: Points_500_mp4,
                  webm: Points_500_webm
                });
            }
            if (response?.lotteryPrize?.prize?.code === '250') {
              checkDevice
                ? setIOSAnimation(<PointsIOS250 locale={shopLang} />)
                : setPointsVideo({
                  mp4: Points_250_mp4,
                  webm: Points_250_webm
                });
            }

            return handleControlAfterGame(+response?.lotteryPrize?.prize?.code || 0);
          } else {
            APIService.setIsUserWon(false);
            checkDevice
              ? setIOSAnimation(<LostIOS locale={shopLang} />) //Lose animation
              : setPointsVideo(null);

            return handleControlAfterGame(0);
          }
        });
    }
  }, [defineWinner, history, location, handleControlAfterGame, checkDevice]);

  const showModal = useCallback(() => {
    const dateNow = Date.now();
    const accessExpiresAt = localStorage.getItem('accessExpiresAt');
    const authorized = localStorage.getItem('authorized');

    if (authorized && accessExpiresAt && (dateNow >= +accessExpiresAt)) {
      clearLocaleStorage();
      history.push({
        pathname: '/auth/login',
        state: {
          prevPath: location.pathname
        }
      });
    } else {
      if (!!keysGold) {
        shopLang !== 'nl_nl' ? APIService.setIsShowModal(true) : onPlayGame();
      }
    }
  }, [onPlayGame, keysGold, history, location]);

  const onHoverChest = useCallback((action: 'onMouseEnter' | 'onMouseLeave') => {
    if (action === 'onMouseEnter' || isShowModal) {
      chestBoxRef?.current?.classList.add('hover');
    } else {
      chestBoxRef?.current?.classList.remove('hover');
    }
  }, [isShowModal]);

  const clearHoverAllChest = () => {
    chestBoxRef?.current?.classList?.remove('hover');
  };

  const handleClick = (chestNum: number) => {
    if (keysGold && !activeVideo && !activeChest) {
      APIService.setIsDefineWinnerCompleted(false);
      APIService.setIsUserWon(false);
      showModal();
      setActiveChest(chestNum);
    }
    if (!keysGold && windowSize < 768) {
      !isInfoChestShow ? setIsInfoChestShow(true) : setIsInfoChestShow(false);
    }
  };

  const onEndedVideo1 = () => {
    if (!isModalChestOpen) {
      if (!!pointsVideo) { //user wins points
        chestVideoRef?.current?.pause();
        setActiveVideo(2);
        points_VideoRef.current?.play();

        if (currentPrize?.prize?.code === '1000000') {
          chestAnimationRef?.current?.classList.add(`scaleMainPrize`);

          setTimeout(() => {
            points_VideoRef.current?.pause();
            onEndedVideo3();

            setIsModalChestOpen(false);
            APIService.setIsDefineWinnerCompleted(true);
          }, 2000);

        } else {
          setTimeout(() => {
            points_VideoRef.current?.pause();
            onEndedVideo3();
          }, 3000);
        }
      } else {//user does not win points
        onEndedVideo3();
      }
    }
  };

  const onEndedVideo3 = useCallback(() => {
    if (currentPrize?.prize?.code !== '1000000') {
      setIsModalChestOpen(true);
      chestAnimationRef?.current?.classList.add(`completed-chest-animation-${activeChest}`); // передвигает в модальное окно
    }

    APIService.handleSetWinPoints(winPointsCounter.current);
    APIService.handleSetTransactions(tokensCounter.current);
  }, [setIsModalChestOpen, activeChest, currentPrize]);

  const onChestBack = useCallback(() => {
    if (currentPrize?.prize?.code !== '1000000') {
      chestAnimationRef?.current?.classList.remove(`completed-chest-animation-${activeChest}`);
    }

    if (windowSize < 768) {
      chestAnimationRef?.current?.classList.add('hide-chest-animation-mob');
    }

    clearHoverAllChest();

    setIsModalChestOpen(false);
    APIService.setIsDefineWinnerCompleted(true);

    setTimeout(() => {
      setActiveVideo(0);
      setActiveChest(0);
      setPointsVideo(null);
      APIService.setIsGameResultAnimated(false);
      APIService.setIsModalAnswerCorrect(false);
    }, 300); //time for animation
  }, [activeChest, windowSize, currentPrize]);

  useEffect(() => {
    if (isModalAnswerCorrect) {
      activeChest && onPlayGame();
    }
  }, [isModalAnswerCorrect, activeChest, onPlayGame]);

  useEffect(() => {
    // !!!!!!!! run after game result
    if (activeChest && isGameResultAnimated) {
      chestAnimationRef?.current?.classList?.add(`active-chest-animation-${activeChest}`);

      if (currentPrize?.prize?.code === '1000000') {
        chestAnimationRef?.current?.classList?.add(`main-prize-animation`);
      }
    }
  }, [activeChest, isGameResultAnimated, pointsVideo, currentPrize]);

  const onStartVideoAnimation = useCallback(() => {
    // !!!!!!!! run after game result (isGameResultAnimated) and loaded video
    if (activeChest && isGameResultAnimated && !activeVideo) {
      setActiveVideo(1);
      chestVideoRef?.current?.play();
    }
  }, [activeChest, isGameResultAnimated, activeVideo]);

  useEffect(() => { //resize hover effect desktop
    if (activeChest && windowSize >= 768) {
      onHoverChest('onMouseEnter');
    }
    if (activeChest && windowSize < 768) {
      clearHoverAllChest();
    }
  }, [windowSize, activeChest, onHoverChest]);

  useEffect(() => {
    if (windowSize <= 768) {
      const handleClickOutside = (e: any) => {
        const chestBox = chestBoxRef?.current?.getElementsByTagName('img')[0]?.contains(e.target);
        !chestBox && setIsInfoChestShow(false);
      };
      document.addEventListener('click', handleClickOutside, true);

      return () => {
        document.removeEventListener('click', handleClickOutside, true);
      };
    }
  }, [windowSize]);

  useEffect(() => {
    if (!isShowModal && !isModalAnswerCorrect) {
      setActiveChest(0);
      clearHoverAllChest();
    }
  }, [isShowModal, isModalAnswerCorrect]);

  useEffect(() => {
    // IOS animation
    if (checkDevice && activeChest && isGameResultAnimated) {
      setTimeout(() => {
        chestAnimationRef?.current?.classList.add(`scaleMainPrize`);
        setIsModalChestOpen(false);
        APIService.setIsDefineWinnerCompleted(true);
        onEndedVideo3();
      }, isUserWon ? (TIME_IOS_ANIMATION * 2 + 1000) : (TIME_IOS_ANIMATION + 1000));
    }
  }, [isUserWon, checkDevice, activeChest, isGameResultAnimated, onEndedVideo3]);


  const chestAnimation = (
    <ChestAnimation ref={chestAnimationRef}>
      {
        checkDevice ?
          IOSAnimation : (
            isUserWon ?
              <>
                <video ref={chestVideoRef}
                       muted
                       playsInline
                       style={activeVideo !== 0 ? { display: 'block' } : { display: 'none' }}
                       onCanPlayThrough={onStartVideoAnimation}
                       onEnded={onEndedVideo1}
                       className={'chestVideo'}>
                  <source src={shopLang === 'be_fr' ? Chest_win_fr_mp4 : Chest_win_nl_mp4} type='video/mp4' />
                  <source src={shopLang === 'be_fr' ? Chest_win_fr_webm : Chest_win_nl_webm} type='video/webm' />
                  Your browser does not support the video tag.
                </video>
                {!!pointsVideo &&
                  <video ref={points_VideoRef}
                         muted
                         playsInline
                         style={activeVideo === 2 ? { display: 'block' } : { display: 'none' }}
                         className={'pointsVideo'}>
                    <source src={pointsVideo?.mp4} type='video/mp4' />
                    <source src={pointsVideo?.webm} type='video/webm' />
                    Your browser does not support the video tag.
                  </video>
                }
              </> :
              <video ref={chestVideoRef}
                     muted
                     playsInline
                     style={activeVideo !== 0 ? { display: 'block' } : { display: 'none' }}
                     onCanPlayThrough={onStartVideoAnimation}
                     onEnded={onEndedVideo1}>
                <source src={shopLang === 'be_fr' ? Lose_fr_mp4 : Lose_nl_mp4} type='video/mp4' />
                <source src={shopLang === 'be_fr' ? Lose_fr_webm : Lose_nl_webm} type='video/webm' />
                Your browser does not support the video tag.
              </video>
          )
      }
    </ChestAnimation>
  );

  return (
    <>
      <CSSTransition
        in={windowSize >= 768 ? isModalChestOpen : isGameResultAnimated}
        // in={windowSize >= 768 ? isModalChestOpen : currentPrize?.prize?.code !== '1000000' ? isGameResultAnimated : false}
        timeout={{ appear: 100, enter: 100, exit: 100 }}
        classNames='anim-slow'
        unmountOnExit
      >
        <Overlay />
      </CSSTransition>

      <ChestWrapper mainPrize={currentPrize?.prize?.code === '1000000'}>
        <CSSTransition
          in={Boolean(activeChest) && isGameResultAnimated}
          timeout={{ appear: 100, enter: 100, exit: 100 }}
          classNames='anim-slow'
          unmountOnExit
        >
          {chestAnimation}
        </CSSTransition>

        <CSSTransition
          in={isModalChestOpen && windowSize < 768 && currentPrize?.prize?.code !== '1000000'}
          timeout={{ appear: 1000, enter: 1000, exit: 300 }}
          classNames='anim'
          unmountOnExit
        >
          <ResultModal onChestBack={onChestBack} />
        </CSSTransition>

        <ChestRow ref={chestRowRef}>
          <ChestBox
            ref={chestBoxRef}
            className={'ChestBox2'}
            shadow={false}>
            <ChestInner>
              <img
                src={shopLang === 'be_fr' ? ChestPosterFR : ChestPosterNL}
                alt='chest'
                onClick={() => handleClick(2)}
                onMouseEnter={() => {
                  windowSize >= 768 && !activeChest && keysGold && onHoverChest('onMouseEnter');
                  windowSize >= 768 && !keysGold && (isDefineWinnerCompleted || !isReplayGame) && setIsInfoChestShow(true);
                }}
                onMouseLeave={(e) => {
                  windowSize >= 768 && !activeChest && keysGold && onHoverChest('onMouseLeave');
                  windowSize >= 768 && !keysGold && (isDefineWinnerCompleted || !isReplayGame) && setIsInfoChestShow(false);
                }}
                style={activeChest !== 2 || (activeVideo === 0 && !checkDevice) ? { opacity: 1 } : { opacity: 0 }} />
            </ChestInner>
          </ChestBox>

          <CSSTransition
            in={isModalChestOpen && windowSize >= 768 && currentPrize?.prize?.code !== '1000000'}
            timeout={{ appear: 1000, enter: 1000, exit: 300 }}
            classNames='anim'
            unmountOnExit
          >
            <ResultModal onChestBack={onChestBack} />
          </CSSTransition>

          <CSSTransition
            in={!keysGold && isInfoChestShow}
            timeout={{ appear: 1000, enter: 1000, exit: 300 }}
            classNames='anim'
            unmountOnExit
          >
            <Toast
              onMouseEnter={() => windowSize >= 768 && !keysGold && (isDefineWinnerCompleted || !isReplayGame) && setIsInfoChestShow(true)}
              onMouseLeave={() => windowSize >= 768 && !keysGold && (isDefineWinnerCompleted || !isReplayGame) && setIsInfoChestShow(false)}
              type={'goldChest'}
              message={i18n.t('eyc_2024_2_hover_chest')} />
          </CSSTransition>
        </ChestRow>
      </ChestWrapper>
    </>
  );
}

export default Chest;